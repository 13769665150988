<template>
    <Transition @enter="onEnter" @after-leave="onAfterLeave">
    <div v-if="screen == 'page2'" class="view">
        <div class="content">
            <h1 class="slide-in">{{ data.page_2.headline }}</h1>

                <!-- <p class="slide-in">Global Var: '{{ globalVar }}'</p> -->
                
            <p class="slide-in">2 Videos: Pre Cached in PouchDB</p>

            <video class="slide-in" width="640" height="420" preload="auto" controls>
                <source :src="videoFiles.fussball_1" type="video/mp4">
            </video>
            <video class="slide-in" width="640" height="420" preload="auto" controls>
                <source :src="videoFiles.fussball_2" type="video/mp4">
            </video>
            
            <p class="slide-in" v-for="{ p } in data.page_2.text_1" :key="p">{{ p }}</p>

            <p class="slide-in">Video Pre Cached in JS Variable</p>

            <video class="slide-in" width="640" height="420" preload="auto" controls>
                <!-- <source src="../assets/video/video_1920.mp4" type="video/mp4"> -->
                <source :src="videoUrl" type="video/mp4">
            </video>

            <p class="slide-in" v-for="{ p } in data.page_2.text_2" :key="p">{{ p }}</p>

            <img class="slide-in" src="../assets/img/testbild.jpg" />

            <p class="slide-in" v-for="{ p } in data.page_2.text_3" :key="p">{{ p }}</p>
        </div>
    </div>
    </Transition>
</template>

<script>
export default {
    name: 'SubPage',
    props: {
        screen: String,
        data: Object
    },
    methods: {
        onEnter() {
            this.videoWidget()
        },
        onAfterLeave() {
            // window.scrollTo(0,0);
        },
        videoWidget() {
            const myTest = document.querySelector('video')
            console.log('myTest', myTest)

            // alert('Analytics firing')

            // setTimeout( function() {
            //     myTest.style.backgroundColor = '#900'
            // }, 1000)
        }
    },
    mounted() {
      this.ininDatabase('videos2')
      this.myGlobalFunction('Overwrite Page')
      this.downloadVideo('media/video_1920.mp4')
    }
}


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    // .my_component {
    //     padding: 20px;
    //     line-height: 1.5;
    //     // background-color: #090;
    // }
</style>
  