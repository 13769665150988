<template>
  <div id="app">
    <div v-if="pong">
      <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
      <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
      <div class="page_nav">
          <div class="section">
            <button @click="screen = 'home'" :class="{ active: screen == 'home' }">Home screen</button>
            <button @click="screen = 'page2'" :class="{ active: screen == 'page2' }">Page 2</button>
          </div>
        </div>
      <HomePage :data="data" :screen="screen" />
      <SubPage2 :data="data" :screen="screen" />
    </div>
    <div v-if="!pong">
      Login
    </div>
  </div>
</template>

<script>
import jsonData from './assets/data/data.json'
// import HelloWorld from './components/HelloWorld.vue'
import HomePage from './components/HomePage.vue'
import SubPage2 from './components/SubPage2.vue'

export default {
    name: 'App',
    components: {
      // HelloWorld,
      HomePage,
      SubPage2
    },

    data() {
      return {
        screen: 'home',
        pong: true,
          data: jsonData,
          // globalVar: 'The Video Files',
          // db: []
      }
    },
    mounted() {
      console.log('Mounting App.vue')
      // console.log('jsonData', jsonData)
      // this.globalVar = 'Bla'// NOT WORKING !!!
    }

    // mounted() {
    //   // // `this` refers to the component instance.
    //   // console.log(this.data)

    //   fetch("http://www.lukasgriese.de/wp-json/")
    //   // fetch("./assets/data.json")
    //       .then(response => response.json())
    //       .then(data => (this.data = data));
    //       // console.log('Data:',this.data)
    // }
}
</script>

<style lang="scss">

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  // // margin-top: 60px;

  // position: relative;
  // max-width: 440px;
  // margin: 0 auto 5rem;
  // // background-color: #900;
  // height: 100vh;
}
</style>
