<template>
    <Transition appear @enter="onEnter" @after-leave="onAfterLeave">
    <div v-if="screen == 'home'" class="view">
        <div class="content">
            <h1 class="slide-in">{{ data.homepage.headline }}</h1>

                <!-- <p class="slide-in">Global Var: '{{ globalVar }}'</p> -->

            <p class="slide-in" v-for="{ p } in data.homepage.text_1" :key="p">{{ p }}</p>
            
            <img class="slide-in" src="../assets/img/logo.png" />

            <p class="slide-in" v-for="{ p } in data.homepage.text_2" :key="p">{{ p }}</p>
        </div>
    </div>
    </Transition>
</template>

<script>
export default {
    name: 'HomePage',
    props: {
        screen: String,
        data: Object
    },
    methods: {
        onEnter() {
            // window.scrollTo(0,0);
        },
        onAfterLeave() {
            // window.scrollTo(0,0);
        }
    },
    mounted() {
      this.myGlobalFunction('Overwrite Home')
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    // .my_component {
    //     padding: 20px;
    //     line-height: 1.5;
    // }
</style>
  