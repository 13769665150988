import Vue from 'vue'
import App from './App.vue'
import PouchDB from 'pouchdb-browser'
import jsonVideo from './assets/data/videos.json'
import './registerServiceWorker'
import './assets/scss/styles.scss'

Vue.config.productionTip = false

Vue.mixin({
  
    data() {
      return {
        globalVar: 'The Video Files',
        videoUrl: "",
        db: [],
        videoFiles: {}
      }
    },
    
    methods: {

      ininDatabase(name) {
        let vm = this
        // // console.log('jsonVideo:', jsonVideo)
        // console.log('jsonVideo.length:', jsonVideo.length)
        console.log('jsonVideo.id:', jsonVideo.fussball_1._id)

        this.db = new PouchDB(name)
        // this.db.info().
        //   then(function (info) {
        //     console.log('db.info:', info);
        //   })

        // Wait for pouchDB to respond
        this.db.info()
          .then(function (info) {
            // PouchDB is responding, go ahead...
            console.log('db.info:', info);
          })
          .then(function () {
            // Take video info from JSON and load files
            for(let key in jsonVideo) {
              console.log('VIDEO (init):', jsonVideo[key]._id)
              // vm.saveVideoToDB(jsonVideo[key])
              vm.fetchToBlob(jsonVideo[key])
            }
          })
      },

      fetchToBlob(el) {
        let vm = this;
        // Check if ID already exists in DB
        this.db.getAttachment(el._id, 'video')
          .then(function(myBlob) {
            // ID already exists...
            console.log( 'GET ATTACHMENT OK:', myBlob )
            // Get JSON ID 
            const id = el._id
            // Convert data to objectURL
            const objectURL = URL.createObjectURL(myBlob)
            // Map objectURL to 'videoFiles' (in vue global data) 
            vm.videoFiles[id] = objectURL
          })
          .catch(function (err) {
            // ID is not existing (first load)...
            console.log( 'GET ATTACHMENT ERROR:', err )
            // Fetch Media Element from server
            let url = el.path + el.filename
            fetch(url)
              .then((response) => response.blob())
              .then((myBlob) => {
                // const objectURL = URL.createObjectURL(myBlob);
                // console.log('fetchToBlob - objectURL:', objectURL)

                // and save in pouchDB 
                vm.saveVideoToDB(el, myBlob)
    
                vm.videoFiles['kruzzi'] = {
                  'wert_1': 'fuzzi'
                }
                console.log('XXX vm.videoFiles:', vm.videoFiles)
              })
          })
      },

      saveVideoToDB(el, blob) {
        console.log('saveVideoToDB - VIDEO ID:', el._id, el.filename, blob, el.content_type)

        let vm = this

        this.db.putAttachment(el._id, 'video', blob, el.content_type)
          .then(function () {
            return vm.db.getAttachment(el._id, 'video');
          })
          .then(function (doc) {
            console.log('doc', doc);
            let id = doc.id
            vm.videoFiles[id] = doc
            console.log('vm.videoFiles:', vm.videoFiles)
          })
          .catch(function (err) {
            let id = el._id
            // let data = vm.db.getAttachment(id, 'video')
            vm.db.getAttachment(id, 'video')
              .then(function(result) {
                const objectURL = URL.createObjectURL(result);
                vm.videoFiles[id] = objectURL
                console.log('doc', result);
              })

            console.log(err);
          })
          
        console.log('FINAL vm.videoFiles:', vm.videoFiles)

      //   this.db.post({
      //     _id: el._id,
      //     _attachments: {
      //         'video.mp4': {
      //           content_type: el.content_type,
      //           data: blob
      //       }
      //     }
      //   }).then(function () {
      //     console.log('DB DB DB DB DB', vm.db.get('fussball_2'))
      //   }).catch(function (err) {
      //     console.log(err);
      //   })
      },


      myGlobalFunction(text) {
        // alert("Hello world")
        console.log('myGlobalFunction called (main.js)')
        this.globalVar = text
      },
      downloadVideo(url) {
        var that = this;
        var xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        xhr.responseType = 'blob';
        xhr.onload = function () {
          var urlCreator = window.URL || window.webkitURL;
          var imageUrl = urlCreator.createObjectURL(this.response);
          // console.log('xhr:', imageUrl)
          that.videoUrl = imageUrl
          // console.log('videoUrl:', that.videoUrl)
        };
        xhr.send();
      }
    },
    // mounted() { // Erzeugt 6-fache Wiederholung des Vorgangs
    //   console.log('Mounted (main.js)')
    // }
})

new Vue({
  render: h => h(App),
  
}).$mount('#app')
